import React, {useEffect, useState} from 'react'

const Sales = (props) => {

  const [netsales , setNetsales] = useState(0)
  const [netpool, setNetpool] = useState(0)
  const [majorpool, setMajorpool] = useState(null)

  const putSpaces = (str) => {
    const arr = str.reverse()
    arr.splice(6, 0, ' ')
    if(arr.length > 9) arr.splice(10, 0, ' ')
    const arr2 = arr.reverse()
    return arr2.join('')
   }

  useEffect(() => {
    const np = props.pool.netPool.split('')
    const ns = props.pool.netSales.split('')
    const np2 = putSpaces(np)
    const ns2 = putSpaces(ns)
    setNetsales(ns2)
    setNetpool(np2)
    if(props.pool.majorPool !== '0,00') {
      const mp = props.pool.majorPool.split('')
      const mp2 = putSpaces(mp)
      setMajorpool(mp2)
    } else {
      setMajorpool(null)
    }
  }, [props])

  return(
    <div className='sales'>
      <div className='cell'>
        net sales: {netsales} €
      </div>
      <div className='cell'>
        net pool: {netpool} €
      </div>
      {majorpool ? 
      <div className='cell'>
        major pool: {majorpool} €
      </div> : null}
    </div>
  )

}

export default Sales