import React, {useState} from 'react'

const PoolLink = (props) => {

  const [hovering, setHovering] = useState(false)
  
  const style = {
    'height': '48px',
    'border': '1px solid black',
    'width': '25%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'backgroundColor': props.toShow ? 'white' : hovering ? 'white' : props.pool.color,
    'cursor': 'pointer'
  }

  const hover = () => {
    setHovering(true)
  }

  const mouseOut = () => {
    setHovering(false)
  }

  return (
    <div style={style} 
      onMouseOver={() => hover(props.pool.uuid)}
      onMouseLeave={() => mouseOut(props.pool.uuid)}
      onClick={() => props.change(props.pool.uuid)}>
      {props.pool.trackName + ' ' + props.pool.type.toUpperCase()}
    </div>
  )
}

export default PoolLink