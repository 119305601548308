import React, {useEffect, useState} from 'react'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

const LineChart = (props) => {

  const [chartData, setChartData] = useState(null)
  const [options, setOptions] = useState({})
  // const [height, setHeight] = useState('500px')

  useEffect(() => {
    const horses =props.horses
    const labels = Object.keys(horses[0].percentages)
    const datasets = []
    let highest = 0
    for( let i = 0; i < horses.length; i++ ) {
      const percents = Object.values(horses[i].percentages)
      percents.forEach(percent => {
        if(percent > highest) highest = percent
      })
      const dataset = {}
      dataset.data = percents
      dataset.id = props.raceId
      dataset.backgroundColor = horses[i].color
      dataset.borderColor = horses[i].color
      dataset.fill = false
      dataset.borderWidth = 1
      dataset.label = horses[i].horseName
      datasets[i] = dataset
    }
    highest = (Math.ceil(highest/10))*10 
    const data = {labels: labels, datasets: datasets}
    setChartData(data)
    const chartElement = document.querySelector('.chart')
    const ratio = chartElement.offsetWidth / chartElement.offsetHeight
    setOptions({
      scales: { y: {min: 0, max: highest} },
      responsive: true,
      aspectRatio: ratio,
      maintainAspectRatio: true,
      elements: { point: {radius: 2}}
    })
    const id = setInterval(() => {
      window.location.reload(false) // <-- (3) invoke in interval callback
    }, 600000);
    return () => clearInterval(id)
  }, [props])

  return (
    <div className='lineChart'>
      {chartData && <Chart data={chartData} type='line' options={options} />} 
    </div>
  )
}

export default LineChart;