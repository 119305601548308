import React, {useState} from 'react'

const chooseAll = (props) => {

	const [hovering, setHovering] = useState(false)

	const nameColumn = {
		'backgroundColor': hovering ? 'rgb(0, 255, 237, 0.2)' : 'white' 
	}

	const percentColumn = {
    'color': 'black',
		'textAlign': 'center',
  }

	const cell = {
    'backgroundColor': 'black',
    'width': '1em',
    'color': 'white',
    'textAlign': 'center',
		'verticalAlign': 'middle'
  }

  const line = {
    'cursor': 'pointer',
    'fontSize': '17px'
  }

	const hover = () => {
		setHovering(true)
	}
	
	const mouseOut = () => {
		setHovering(false)
	}

	return(
		<tr style={line} onClick={() => props.add()} onMouseOver={() => hover()} onMouseLeave={() => mouseOut()}>
			<td style={nameColumn}>choose all - remove all</td>
			<td style={percentColumn}></td>
			<td style={cell}></td>
		</tr>
	)
}

export default chooseAll