import CookieConsent from "react-cookie-consent"

const CookieBanner = () => {

	return(
		<CookieConsent
			location="top"
			buttonText="I understand"
			sameSite="lax"
			cookieName="ca"
			style={{ background: "black", height: "10%", opacity: '0.85', verticalalign: 'center' }}
			buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
			expires={365}
		>
			This website uses cookies to enhance the user experience.{" "}
			<span style={{ fontSize: "10px" }}>We use only session cookies.</span>
		</CookieConsent>
	)
}

export default CookieBanner