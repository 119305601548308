import React from 'react'
import arrow from './pictures/arrowLeft.png'

const Prompt = () => {

  const pic = {
    'width' : 100,
    'height' : 76
  }

  const prompt = {
    'marginLeft': '5%'
  }

  return (
    <div style={prompt}>
      <h2>Choose horses from the list to add to chart and remove from chart</h2>
      <img src={arrow} alt='arrow left' style={pic}></img>
      <div style ={{'marginTop': '5%'}}>
        Little arrows in horselist indicates relatively descending and ascending horses during last hour
      </div>
    </div>
  )
}

export default Prompt