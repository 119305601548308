import React, {useEffect, useState} from 'react'
import { DateTime } from "luxon"
import axios from 'axios'
import trimData from './trimData'
import {isMobile} from 'react-device-detect'
import RaceLink from './components/RaceLink'
import PoolLink from './components/PoolLink'
import Runner from './components/Runner'
import Sales from './components/Sales'
import Wait from './components/Wait'
import Prompt from './components/Prompt'
import LineChart from './components/LineChart'
import ChooseAll from './components/ChooseAll'
import CookieBanner from './components/CookieBanner'

function App() {

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [pools, setPools] = useState(null)
  const [poolToShow, setPoolToShow] = useState(null)
  const [raceToShow, setRaceToShow] = useState(null)
  const [horsesInChart, setHorsesInChart] = useState([])
	const [seven, setSeven] = useState(false)

  const getCookieValue = (name) => {
    let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
    return result ? result.pop() : ""
  }

  useEffect(() => {
    const fetchData = async () => {
      setError(false)
      setLoading(true)
      try {
        const response = await axios.get('/api/pools')
        const pd = response.data
        const poolsData = trimData(pd)
        const cookies = getCookieValue('settings')
        if(cookies && poolsData && poolsData.find(pool => pool.uuid === JSON.parse(cookies).pts)) {
          const settings = JSON.parse(cookies)   
          const pts = poolsData.find(pool => pool.uuid === settings.pts)
          setPoolToShow(pts)
          const rts = pts.races.find(race => race.raceId === settings.rts)
          setRaceToShow(rts)
          if(settings.hic) {
            const hic = []
            for(let i = 0; i < settings.hic.length; i++ ) {
              hic[i] = rts.runners.find(horse => horse.runnerId === settings.hic[i])
            }
            setHorsesInChart(hic)
          }
        } else {
          setPoolToShow(poolsData[0])
          const now = Date.now()
          let index = 0
          for ( let i = 0; i < poolsData[0].races.length; i++ ) {
            if(poolsData[0].races[i].startTime < now) index = i+1 > poolsData[0].races.length-1 ? poolsData[0].races.length-1 : i+1
          }
          const rts = poolsData[0].races[index]
          setRaceToShow(rts)
        }
        setPools(poolsData)
				for ( let i = 0; i < poolsData.length; i ++ ) {
					if ( poolsData[i].sevenReady ) setSeven(true)
				}
      } catch(error) {
        console.log('ERROR: ', error)
        setError(true)
      }
      setLoading(false);
    }
    const id = setInterval(() => {
      fetchData(); // <-- (3) invoke in interval callback
    }, 600000);

    fetchData(); // <-- (2) invoke on mount
    return () => clearInterval(id)
  }, [])

  const changePool = (id) => {
    const pts = pools.find(pool => pool.uuid === id)
    const now = Date.now()
    let index = 0
    for (let i = 0; i < pts.races.length; i++) {
      if(pts.races[i].startTime < now) index = i+1 > pts.races.length-1 ? pts.races.length-1 : i+1
    }
    const rts = pts.races[index]
    const ptsDate = new Date(pts.races[pts.races.length-1].startTime + 300000)
    const cookieText = JSON.stringify({'pts': pts.uuid, 'rts': rts.raceId})
    document.cookie = "settings="+cookieText+"; expires="+now+"; SameSite=lax"
    setPoolToShow(pts)
    setRaceToShow(rts)
    setHorsesInChart([])
  }

  const changeRace = (id) => {
    const rts = poolToShow.races.find(race => race.raceId === id)
    const session = Date.now()
    const cookieText = JSON.stringify({'pts': poolToShow.uuid, 'rts': rts.raceId})
    document.cookie = "settings="+cookieText+"; expires="+session+"; SameSite=lax"
    setRaceToShow(rts)
    setHorsesInChart([])
  }

  const addHorse = (id) => {
    let newHorses = []
    const found = horsesInChart.find(horse => horse.runnerId === id )
    if (found) {
      newHorses = horsesInChart.filter(horse => horse.runnerId !== id)
    } else {
      const horseToAdd = raceToShow.runners.find(horse => horse.runnerId === id)
      newHorses = horsesInChart.concat(horseToAdd)
    }
    const nh = newHorses.map(horse => horse.runnerId)
    const session = Date.now()
    const cookieText = JSON.stringify({'pts': poolToShow.uuid, 'rts': raceToShow.raceId, 'hic': nh})
    document.cookie = "settings="+cookieText+"; expires="+session+"; SameSite=lax"
    setHorsesInChart(newHorses)
  }

  const addAll = () => {
    let newHorses = []
    if(horsesInChart.length === 0) newHorses = [].concat(raceToShow.runners)
    const nh = newHorses.map(horse => horse.runnerId)
    const session = Date.now()
    const cookieText = JSON.stringify({'pts': poolToShow.uuid, 'rts': raceToShow.raceId, 'hic': nh})
    document.cookie = "settings="+cookieText+"; expires="+session+"; SameSite=lax"
    setHorsesInChart(newHorses)
  }

  const getWaitTime = () => {
    const publishTime = DateTime.fromObject({ hour: 13, minute: 0, second: 10 }, { zone: 'Europe/Helsinki' })
		const now = Math.floor(Date.now() / 1000)
		let pt = Math.floor(publishTime.ts / 1000)
    if ( pt < now ) {
      const tomorrow = publishTime.plus({ days: 1 })
      pt = Math.floor(tomorrow.ts / 1000)
    }    
    return pt
  }

  return (
    <div>
			{ isMobile ? <div>This content is unavailable on mobile</div> :
      isError ? <Wait time={getWaitTime()} /> :
      isLoading ? <div>Loading</div> :
      raceToShow && seven === false ? <Wait time={getWaitTime()} /> :
      <div>
        <div className='poolLinkContainer'>
          {pools && poolToShow && pools.map( pool => <PoolLink key={pool.uuid} pool={pool} change={changePool}
          toShow={poolToShow.uuid === pool.uuid}/>) }
        </div>
        <div className='raceLinkContainer'>
          {poolToShow && raceToShow && poolToShow.races.map( race => <RaceLink key={race.raceId} color={poolToShow.color} race={race} 
            change={changeRace} toShow={raceToShow.raceId === race.raceId}/>) }
        </div>
        {poolToShow && <Sales pool={poolToShow}/>}
        <div className='chartContainer'>
          <div className='horseList'>
            <table className='table'>
              <tbody>
                {raceToShow && raceToShow.runners.map( horse => <Runner key={horse.runnerId} horse={horse} 
                add={addHorse}/> )}
                <ChooseAll add={addAll}/>
              </tbody>
            </table>
          </div>
          <div className='chart'>
            {horsesInChart.length > 0 ? <LineChart horses={horsesInChart} raceId={raceToShow.raceId}/> : <Prompt/>}
          </div>
        </div>
      </div> 
      }
      <CookieBanner/>
    </div>
  )
}

export default App
