import React, {useState, useEffect} from 'react'

const Wait = (props) => {

  const [timeLeft, setTimeLeft] = useState({})
  const [toWait, setToWait] = useState(props.time)

  const calculateTimeLeft = () => {
    const now = Math.floor(Date.now() / 1000)
    let difference = toWait - now
    let tl = {};

    if (difference > 0) {
      tl = {
        hours: Math.floor(difference / 3600),
        minutes: Math.floor(difference % 3600 / 60),
        seconds: difference % 3600 % 60
      }
      if(tl.seconds < 10) tl.seconds = '0'+tl.seconds.toString()
      else tl.seconds = tl.seconds.toString()
      if(tl.minutes < 10) tl.minutes = '0'+tl.minutes.toString()
      else tl.minutes = tl.minutes.toString()
      if(tl.hours < 10) tl.hours = '0'+tl.hours.toString()
      else tl.hours = tl.hours.toString()
      setTimeLeft(tl)
    }
    return tl;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft()
    }, 1000);
    return () => clearTimeout(timer)
  }, [])

  return(
    <div className='clock'>
      <h1>Percents are ready in {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</h1>
    </div>
  )
}

export default Wait