import React, {useState} from 'react'

const RaceLink = (props) => {

  const [hovering, setHovering] = useState(false)

  const style = {
    'height': '38px',
    'border': '1px solid black',
    'width': '25%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    'backgroundColor': props.toShow ? 'white' : hovering ? 'white' : props.color
  }

  const hover = () => {
    setHovering(true)
  }

  const mouseOut = () => {
    setHovering(false)
  }

  return (
    <div style={style} 
      onMouseOver={() => hover(props.race.raceId)}
      onMouseLeave={() => mouseOut(props.race.raceId)}
      onClick={() => props.change(props.race.raceId)}>
      leg {+ props.race.leg + ' - race ' + props.race.race}
    </div>
  )
}

export default RaceLink