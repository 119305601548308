const trimData = (pools) => {

  const colors = ['BlueViolet', 'SpringGreen', 'RebeccaPurple', 'Red', 'Turquoise', 'Navy', 'DeepPink', 'ForestGreen', 'Indigo',
  'Purple', 'Teal', 'Magenta', 'OrangeRed', 'Sienna', 'Green',
  'Blue']
  
  for(let i = 0; i < pools.length; i++) {
    for(let j = 0; j < pools[i].races.length; j++) {
      const rates = {}
      const withNames = {}
      for(let k = 0; k < pools[i].races[j].runners.length; k++) {
        const horse = pools[i].races[j].runners[k]
        horse.color = colors[k]
        const withoutBrackets = horse.horseName.replace(/\s*\(.*?\)\s*/g, '')
        const withoutStars = withoutBrackets.replace('*', '')
        const withNumber = horse.number.toString()+' '+withoutStars
        horse.horseName = withNumber
        const v = Object.values(horse.percentages)
        const values = v.map(value => parseFloat(value.replace(/,/g, '.')))
        const keys = Object.keys(horse.percentages)
        const newPercentages = {}
        for( let m = 0; m < keys.length; m++ ) {
          const date = new Date()
          date.setTime(keys[m])
          const hours = date.getHours()
          let minutes = date.getMinutes()
          if(minutes === 0) minutes = '00' 
          newPercentages[hours+':'+minutes] = values[m]
        }
        if(values.length > 6 && values[values.length-1] > 1 && !horse.scratched) {
          const rate = values[values.length-1] / values[values.length-7]
          rates[horse.runnerId] = rate
          withNames[horse.horseName] = rate
        }
        horse.percentages = newPercentages
        pools[i].races[j].runners[k] = horse
      }
      if(Object.keys(pools[0].races[0].runners[0].percentages).length > 6) {
        const ratios = Object.values(rates)
        ratios.sort()
        for(let l = 0; l < pools[i].races[j].runners.length; l++) {
          const horse = pools[i].races[j].runners[l]
          const horseRate = rates[horse.runnerId]
          if(horseRate >= ratios[ratios.length-2]) horse.top = true
          if(horseRate <= ratios[1]) horse.bottom = true
          pools[i].races[j].runners[l] = horse
        }
      }
    }
    if(pools[i].country === "SE") pools[i].type = "V"+pools[i].type.substring(1)
  }
  return pools
}
  
  export default trimData