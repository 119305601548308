import React, {useEffect, useState} from 'react'

const Runner = (props) => {

  const [horseName, setHorseName] = useState('')
  const [lastPercent, setLastPercent] = useState(0)
  const [hovering, setHovering] = useState(false)

  useEffect(() => {
    const lastKey = Object.keys(props.horse.percentages)[Object.keys(props.horse.percentages).length-1]
    const lastValue = props.horse.percentages[lastKey]
    setHorseName(props.horse.horseName)
    setLastPercent(lastValue)
  }, [props])

  const nameColumn = {
    'color': props.horse.scratched ? 'red' : 'black',
    'textDecoration': props.horse.scratched ? 'line-through' : 'none',
    'backgroundColor': hovering ? 'rgb(0, 255, 237, 0.2)' : 'white' 
  }

  const percentColumn = {
    'color': props.horse.scratched ? 'red' : 'black'
  }

  const cell = {
    'backgroundColor': props.horse.color,
    'width': '1em',
    'color': 'white',
    'textAlign': 'center',
    'verticalAlign': 'middle'
  }

  const line = {
    'cursor': 'pointer',
    'fontSize': '17px'
  }

  const hover = () => {
    setHovering(true)
  }

  const mouseOut = () => {
    setHovering(false)
  }
  
  return (
    <tr style={line} onClick={() => props.add(props.horse.runnerId)} onMouseOver={() => hover(props.horse.runnerId)}
      onMouseLeave={() => mouseOut(props.horse.runnerId)}>
      <td style={nameColumn}>{horseName}</td>
      <td style={percentColumn}>{lastPercent.toFixed(2) } %</td>
      <td style={cell}>{props.horse.top ? <div>&uarr;</div> : props.horse.bottom ? <div>&darr;</div> : <div></div>}</td>
    </tr>
  )
}

export default Runner